<template>
  <div data-component-name="FeedbackCard" :class="{ active }">
    <q v-html="quote" v-if="!link" />

    <NuxtLink v-else :to="localePathWithSlash(link)" target="_blank">
      <q v-html="quote"/>
    </NuxtLink>

    <div class="row">
      <div class="author">
        <span class="name">{{ author.name }}</span>
        <span class="position">{{ author.position }}</span>
      </div>

      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
import useLocalePathWithSlash from '~/composables/useLocalePathWithSlash';

defineProps<{
  active: boolean

  link?: string

  quote: string

  author: {
    name: string
    position: string
  },
}>();

const localePathWithSlash = useLocalePathWithSlash();
</script>

<style scoped lang="scss">
@import "$/mixins/typography";
@import "$/mixins/media";
@import "$/mixins/flex";
@import "$/mixins/size";

@import "$/variables/shadows";

[data-component-name="FeedbackCard"] {
  box-shadow: 0 0 0 1px token('outline-secondary');
  transition: box-shadow 0.4s ease;

  @include flex-start-start;
  flex-direction: column;
  flex: 1;

  padding: 4rem;

  @include tablet {
    padding: 2.5rem;
  }

  @include mobile {
    padding: 1.5rem 1rem;
  }

  q {
    display: block;
    @include body-1;
    margin-bottom: 2rem;

    @include mobile {
      margin-bottom: 1rem;
    }
  }

  .row {
    @include flex-stretch-sb;
    width: 100%;

    @include mobile {
      flex-direction: column;
      flex: 1;
    }

    .author {
      @include flex-start-sb;
      flex-direction: column;
      gap: 0.25rem;

      @include mobile {
        margin-bottom: 1rem;
      }

      span.name {
        @include subtitle-3;
      }

      span.position {
        @include body-3;
      }
    }
  }

  &.active {
    box-shadow: map-get($shadows, 'Elevation 3');
  }
}
</style>
